import { useState } from 'react'
import Edit from './Edit'

export default function AdminListSection({ content, collection, document  }) {

  const [ activeEdit, setActiveEdit ] = useState(null)

  const post = content.content

  return (
    <>
      <div className="list-section flex a-center j-center column g3 box m-auto">
        <h2 className="list-heading s4 mb3 t-m-center">{content.heading}</h2>
        <div className='list-box flex j-center a-center' dangerouslySetInnerHTML={{__html: post}}></div>
      </div>
      <div className='flex box m-auto a-center j-center'>
      <button className='btn mb1 m-auto t-center' style={{color: 'orange', cursor: 'pointer'}} onClick={() => setActiveEdit(true)}>Edytuj sekcję</button>
      {activeEdit && <Edit post={post} collection={collection} document={document} setActiveEdit={setActiveEdit}/>}
    </div>
    </>
    
  )
}
