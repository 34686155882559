
import {useState} from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { db } from "../../controllers/firebase"
import { doc, updateDoc } from "firebase/firestore";



export default function Editor({ post, collection, document, setActiveEdit }) {

  const [ value, setValue ] = useState(post)

  const handleUpdatePost = async () => {
    const ref = doc(db, collection, document)

    const data = {
      content: value
    }

    await updateDoc(ref, data)
    setActiveEdit(false)
  }

  return (
    <>
    <div className='editor w80'>
      <ReactQuill theme="snow" value={value} onChange={setValue} />
    </div>
    <button className='mt2 btn' onClick={() => handleUpdatePost()}>Zaktualizuj</button>
    </>
    
  );
}
