import React from 'react'
import Editor from './Editor'

export default function Edit({ post, collection, document, setActiveEdit }) {
  return (
    <div className='edit-content'>
      <div className='close-btn t-right mb3 w-600 s2_5' onClick={() => setActiveEdit(false)}>X</div>
      <Editor post={post} collection={collection} document={document} setActiveEdit={setActiveEdit}/>
    </div>
  )
}
