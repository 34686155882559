import { useState } from 'react'

import config from '../../config.json'

import { motion } from 'framer-motion'
import Edit from './Edit'


export default function AdminImageSection({ content, type, collection, document }) {

  const [ activeEdit, setActiveEdit ] = useState(null)

  console.log(document)
  console.log(collection)

  const URL = config.URL

  console.log(content.content)

  const variantsLeft = {
    active: {
      scale: 1.05,
      rotate: 0,
      transition: {
        duration: 0.9,
        type: "spring", 
        stiffness: 100
      }
    },
    initial: {
      opacity: 0,
      x: 400,
      transition: { 
        duration: 0.7
      }
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    }
  }

  const variantsRight = {
    active: {
      scale: 1.05,
      rotate: 0,
      transition: {
        duration: 0.9,
        type: "spring", 
        stiffness: 100
      }
    },
    initial: {
      opacity: 0,
      x: -400,
      transition: { 
        duration: 0.7
      }
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    }
  }

  const variants = type === 'reverse' ? variantsLeft : variantsRight
  
  const post = content.content

  return (
    <>
    <div className={`image-section flex j-start a-center ${type === 'reverse' ? 'row-reverse m-column-reverse' : 'm-column'} mt4 mb4`}>
      <div className={`section-img w100 ${type === 'reverse' ? 'section-img-reverse' : 'section-img-normal'}`}>
        <div className='img-frame'></div>
        <motion.img 
          src={`${URL}/assets/${content.img}`} 
          alt="sabio-domki" 
          className={`${type === 'reverse' ? 'section-img-reverse' : 'section-img-normal'} section-img`} 
          whileHover='active' 
          initial='initial'
          whileInView='animate'
          viewport={{
            once: true,
          }}
          variants={variants}/>
      </div>
      <div className={`section-text w100 ${type === 'reverse' ? 'section-text-reverse' : 'section-text-normal'}`}>
        <div className={`section-text-inner ${type === 'reverse' ? 'section-text-inner-reverse' : 'section-text-inner-normal'}`}>
          <h2 className='s4 mb2 t-m-center'>{content.heading}</h2>
          <div className='basic-par mb1' dangerouslySetInnerHTML={{__html: post}}>
          </div>
        </div>
        
      </div>
      
    </div>
    <div className='flex box m-auto a-center j-center'>
      <button className='btn mb1 m-auto t-center' style={{color: 'orange', cursor: 'pointer'}} onClick={() => setActiveEdit(post)}>Edytuj sekcję</button>
      {activeEdit && <Edit post={post} collection={collection} document={document} setActiveEdit={setActiveEdit}/>}
    </div>
    
    </>
    
  )
}

