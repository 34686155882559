export default function ColumnListSection({ content }) {

  const post = content.content

  return (
    <div className="column-list-section m-auto box">
      <div className='column-list' dangerouslySetInnerHTML={{__html: post}}></div>
    </div>
  )
}
