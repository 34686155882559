import SampleCat from "../components/content/SampleCat";
import MobileSeparator from "../components/layout/MobileSeparator";

import config from '../config.json'

export default function ColorSamples() {

  const URL = config.URL

  const viny_plus_rundprofil = {
    name: 'vinyPlus Rundprofil',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rund_querschnitt_profil.png`,
        alt: 'vinyplus_rund_querschnitt_profil'
      },
      {
        url: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rund_profil.png`,
        alt: 'vinyplus_rund_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_anteak_grey.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_anthrazitgrau_7016.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_basaltgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_bergkiefer.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_cremeweiss_9001.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_dunkelrot_3011.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_goldenoak.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_Grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_lichtgrau_ral7035.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_stahlblau_5011.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_vinyplus_dunkelgruen.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_weiss_ral_9010.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_woodec_turner_oak_toffee.jpg`, width: 514, height: 580 }
    ]
  }

  const plus_quattro = {
    name: 'vinyPlus Quattro',
    headerImgs: [
      {
        url: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_querschnitt_profil.jpg`,
        alt: 'vinyplus_quattro_querschnitt_profil'
      },
      {
        url: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_profil.jpg`,
        alt: 'vinyplus_quattro_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/plus_quattro/viny_quattro_frontal_turner_oak_toffee.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_anteak.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_signalweiss_ultramatt.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_weissbach_eiche.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_woodec_kitami.jpg`, width: 514, height: 580 }
    ]
  }

  const plus_shadow = {
    name: 'vinyPlus Shadow',
    headerImgs: [
      {
        url: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_querschnitt_profil.jpg`,
        alt: 'vinyplus_shadow_querschnitt_profil'
      },
      {
        url: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_profil.jpg`,
        alt: 'vinyplus_shadow_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_woodec_turner_oak_toffee.jpg`, width: 514, height: 580 }
    ]
  }

  const viny_plus_stulpprofil = {
    name: 'vinyPlus Stulpprofil',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelp_profil.jpg`,
        alt: 'vinyplus_stuelp_profil'
      },
      {
        url: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelp_querschnitt_profil-e1643211467941.jpg`,
        alt: 'vinyplus_stuelp_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/sheffield_oak_beton.jpeg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_anteak_grey.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_anthrazitgrau_7016.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_basaltgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_bergkiefer.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_cremeweiss_9001.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_dunkelrot_3011.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_goldenoak.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_Grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_lichtgrau_ral7035.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_stahlblau_5011.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_vinyplus_dunkelgruen.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_weiss_ral_9010.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/vinyplus_stuelpprofil_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-stulpprofil/woodec_turner_oak_toffee.jpeg`, width: 514, height: 580 }
    ]
  }

  const viny_classic = {
    name: 'vinyClassic',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-classic/vinyclassic_profil.jpg`,
        alt: 'vinyclassic_profil'
      },
      {
        url: `${URL}/assets/samples/viny-classic/vinyclassic_querschnitt_profil.jpg`,
        alt: 'vinyclassic_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_creme.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-anthrazit.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-basaltgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-cappuccino.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-hellelfenbein.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-hellgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-signalweiss.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic_frontal_vinyclassic-stone.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-classic/vinyclassic-anthrazit.jpg`, width: 514, height: 580 }
    ]
  }

  const viny_board_design = {
    name: 'vinyBoard Design',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-board-design/vinyboard_design_querschnitt_profil-Kopie.jpg`,
        alt: 'vinyclassic_profil'
      },
      {
        url: `${URL}/assets/samples/viny-board-design/vinyboard_design_profil.jpg`,
        alt: 'vinyclassic_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board-design/vinyboard_design_frontal_woodec_turner_oak_toffee.jpg`, width: 514, height: 580 }
    ]
  }

  const viny_board = {
    name: 'vinyBoard',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-board/vinyBoard_1.jpg`,
        alt: 'vinyclassic_profil'
      },
      {
        url: `${URL}/assets/samples/viny-board/vinyBoard_2.jpg`,
        alt: 'vinyclassic_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_basaltgrau_basalt_grey_gris.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-anthrazit.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-cappuccino.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-claystone.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-creme.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-hellelfenbein.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-hellgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-signalweiss.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-board/vinyboard_frontal_mp-vb-vt-signalweiss.jpg`, width: 514, height: 580 },
    ]
  }

  const viny_top = {
    name: 'vinyTop',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-top/vinytop_profil-2.jpg`,
        alt: 'vinyclassic_profil'
      },
      {
        url: `${URL}/assets/samples/viny-top/vinytop_querschnitt_profil-e1643211991376.jpg`,
        alt: 'vinyclassic_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_basaltgrau_basalt_grey_gris.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-anthrazit.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-cappuccino.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-claystone.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-creme.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-hellelfenbein.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-hellgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-top/vinytop_frontal_mp-vb-vt-signalweiss.jpg`, width: 514, height: 580 }
    ]
  }

  const vinylit_multipaneel = {
    name: 'vinylit Multipaneel',
    headerImgs: [
      {
        url: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_holz_profil.jpg`,
        alt: 'vinyclassic_profil'
      },
      {
        url: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_holz_querschnitt_profil-e1643211729123.jpg`,
        alt: 'vinyclassic_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_basaltgrau_basalt_grey_gris.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-anthrazit.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-cappuccino.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-claystone.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-creme.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-hellelfenbein.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-hellgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel/vinylit_multipaneel_frontal_mp-vb-vt-signalweiss.jpg`, width: 514, height: 580 }
    ]
  }

  const vinylit_multipaneel_design = {
    name: 'vinylit Multipaneel Design',
    headerImgs: [
      {
        url: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_profil.jpg`,
        alt: 'vinyclassic_profil'
      },
      {
        url: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_querschnitt_profil-e1643211800937.jpg`,
        alt: 'vinyclassic_querschnitt_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/vinylit-multipaneel-design/vinylit_multipaneel_design_frontal_woodec_turner_oak_toffee.jpg`, width: 514, height: 580 }
    ]
  }

  
  return (
    <div className="color-samples box m-auto">
      <h2 className="s3 m-auto t-center mb4 mt4">Wzorniki wykończenia elewacji</h2>
      <SampleCat samples={viny_plus_stulpprofil.samples} headerImgs={viny_plus_stulpprofil.headerImgs} name={viny_plus_stulpprofil.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={viny_plus_rundprofil.samples} headerImgs={viny_plus_rundprofil.headerImgs} name={viny_plus_rundprofil.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={plus_shadow.samples} headerImgs={plus_shadow.headerImgs} name={plus_shadow.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={plus_quattro.samples} headerImgs={plus_quattro.headerImgs} name={plus_quattro.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={viny_classic.samples} headerImgs={viny_classic.headerImgs} name={viny_classic.name} />
      <MobileSeparator height={'150px'} />

      <SampleCat samples={viny_board_design.samples} headerImgs={viny_board_design.headerImgs} name={viny_board_design.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={viny_board.samples} headerImgs={viny_board.headerImgs} name={viny_board.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={viny_top.samples} headerImgs={viny_top.headerImgs} name={viny_top.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={vinylit_multipaneel.samples} headerImgs={vinylit_multipaneel.headerImgs} name={vinylit_multipaneel.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={vinylit_multipaneel_design.samples} headerImgs={vinylit_multipaneel_design.headerImgs} name={vinylit_multipaneel_design.name} />
      <MobileSeparator height={'150px'} />
    </div>
  )
}
