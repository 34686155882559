import CookieConsent from "react-cookie-consent";

import React from 'react'

export default function CookieAlert() {
  return (
    <div className="cookie-alert">
      <CookieConsent
        location="bottom"
        buttonText="Zrozumiałem"
        cookieName="cookie-consent-accept"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Na tej stronie używamy plików cookies i podobnych funkcji do przetwarzania informacji o urządzeniach końcowych i danych osobowych. Przetwarzanie jest wykorzystywane do celów takich jak integracja treści, usług zewnętrznych i elementów stron trzecich, analiza/pomiary statystyczne, spersonalizowanie reklam oraz integracja mediów społecznościowych.{" "}
      </CookieConsent>
    </div>
  )
}
