//basic
import { useState, useEffect } from 'react';
// import { AnimatePresence, motion } from 'framer-motion';
import config from '../../config.json' 

export default function HeroImg() {

  const URL = config.URL

  const [id, setId] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (id >= 4) {
        setId(prevId => prevId - 3);
      } else {
        setId(prevId => prevId + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [id, setId]);


  return (
    <div className="hero-img-box">
      <img src={`${URL}/assets/hero-0${id}.jpg`} alt="domek SABIO" className='hero-img'/>
    </div>
  )
}





//   return (
//     <>
//     {error && <div>{error}</div>}
//     <div id='slider' className='slider'>
//       <div className='slider-inner'>
//         <AnimatePresence>
//           <motion.div
//             className='slider-img'
//             key={id}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             transition={{ duration: 0.5, delay: 0.25 }}
//           >
//             <motion.div
//               className='slider-background'
//               style={{ backgroundImage: `url(${img})` }}
//               initial={{scale: 1, y: 0}} animate={{scale: 1.1, y: 25}} transition={{duration: 5}}>
//               <img src={img} alt='slider' className='slider-holder' />
//             </motion.div>
//           </motion.div>
//         </AnimatePresence>
//       </div>
//     </div>
//     </>
//   )
// }


