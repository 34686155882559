import { useCollection } from "../hooks/useCollection"

import config from '../config.json'

import Separator from "../components/layout/Separator";
import Gallery from "../components/layout/Gallery";
import ColorSwitcher from "../components/content/ColorSwitcher";
import AdminImageSection from "../components/admin/AdminImageSection";
import AdminParagraphSection from "../components/admin/AdminParagraphSection";
import AdminColumnListSection from "../components/admin/AdminColumnListSection";

export default function AdminOffer() {

  const { documents } = useCollection('offer-page')
  console.log(documents)

  const URL = config.URL

  const photos = [
    { src: `${URL}/assets/foto/sabio-01.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-02.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-03.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-04.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-05.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-06.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-07.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-08.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-09.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-10.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-11.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-12.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-13.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-14.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-15.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-16.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-17.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-18.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-19.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-20.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-21.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-22.jpg`, width: 2577, height: 1718 },
  ]

  return (
    <div className="offer-page">
      {documents && 
      <div className="content">
        <Separator height={'100px'} />
        <div className="header-section flex j-center a-center box m-column m-auto g3">
          <div className="header-left w100">
            <ColorSwitcher />
          </div>
          <div className="header-right w100">
            <h2 className='s4 mb2 t-m-center'>Jaki jest nasz Mobilny Domek?</h2>
            <AdminParagraphSection content={documents[0]} collection={'offer-page'} document={'section01'} />
          </div>
        </div>
        <Separator height={'100px'} />
        <AdminImageSection content={documents[2]} type={'normal'} collection={'offer-page'} document={'section03'}/>
        <AdminParagraphSection content={documents[3]} collection={'offer-page'} document={'section04'}/>
        <Separator height={'100px'} />
        <AdminImageSection content={documents[9]} type={'reverse'} collection={'offer-page'} document={'section8'}/>
        <Separator height={'100px'} />
        <AdminImageSection content={documents[4]} type={'norlma'} collection={'offer-page'} document={'section05'}/>
        <AdminParagraphSection content={documents[5]} collection={'offer-page'} document={'section06'}/>
        <Separator height={'100px'} />
        <h2 className='s4 mb2 t-center'>Dane techniczne i zalety</h2>
        <div className="list-section flex j-center a-center box m-column m-auto g3">
          <div className="list-left w100">
            <AdminColumnListSection content={documents[6]} collection={'offer-page'} document={'section07a'}/>
          </div>
          <div className="list-right w100">
          <AdminColumnListSection content={documents[7]} collection={'offer-page'} document={'section07b'}/>
          </div>
        </div>
        <Separator height={'40px'} />
        <div className="offer-list">
          <AdminColumnListSection content={documents[8]} collection={'offer-page'} document={'section07c'}/>
        </div>
        <Separator height={'110px'} />
        <h2 className="t-center s3 box m-auto">Możemy tez wykonać takie budynki socjalne (biura, stróżówki, salon fryzjerski, kosmetyczny, sanitarne toalety prysznice)</h2>
        <Separator height={'110px'} />
        <Gallery photos={photos} targetRowHeight={150} />
        <Separator height={'100px'} />
      </div>}
    </div>
  )
}


