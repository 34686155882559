import { useState } from 'react'

import Edit from './Edit'

export default function AdminColumnListSection({ content, collection, document }) {

  const [ activeEdit, setActiveEdit ] = useState(null)

  const post = content.content

  return (
    <div className="column-list-section m-auto box">
      <div className='column-list' dangerouslySetInnerHTML={{__html: post}}></div>
      <div className='flex box m-auto a-center j-center'>
        <button className='btn mb1 m-auto t-center' style={{color: 'orange', cursor: 'pointer'}} onClick={() => setActiveEdit(post)}>Edytuj sekcję</button>
        {activeEdit && <Edit post={post} collection={collection} document={document} setActiveEdit={setActiveEdit}/>}
      </div>
    </div>
  )
}
