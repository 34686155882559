import { useNavigate } from 'react-router'
 
import Button from '../layout/Button'
import HeroImg from './HeroImg'

export default function Hero() {

  const destination = useNavigate()

  const navigate = (link) => {
    destination(link)
  }

  return (
    <div className='hero-bg'>
      <div className='hero flex j-beetween a-center g1 box m-auto m-column-reverse'>
        <div className='hero-left w100'>
          <h1 className='mb2 s3_5 light w-800 _3d'>To nie domek na lato, to domek na <span className='secondary'>LATA</span>!</h1>
          <h3 className='mb2 light w60 s1_5'>Wyjątkowe rozwiązania domków SABIO, to minimum formalności, szybka realizacja, solidna konstrukcja i wygoda użytkowania. Możesz go mieć tam, gdzie chcesz, już w kilka miesięcy!</h3>
          <Button content={'Poznaj nasze domki'} action={navigate} option={'/domki'} alert={'Kliknięcie normalnego przycisku'} type={'btn'}/>
        </div>
        <div className='hero-right w100'>
          <HeroImg />
        </div>
      </div>
    </div>
    
  )
}
