import { NavLink } from "react-router-dom"

export default function AdminArea() {
  return (
    <div className='admin-area box m-auto mt4 mb4'>
      <h2>Edytuj stronę:</h2>
      <div className={`navigation`}>
      <ul className="navigation-list flex m-column g2">
        <li key='1'><NavLink to='/admin/start'>Start</NavLink></li>
        <li key='2'><NavLink to='/admin/domki'>Domki</NavLink></li>
        <li key='3'><NavLink to='/admin/jak-to-dziala'>Jak to działa</NavLink></li>
		  </ul>
    </div>
    </div>
  )
}
