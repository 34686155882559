import { NavLink } from "react-router-dom"

export default function OptionalNavigation({ location }) {
	return(
		<div className={`navigation ${location}`}>
      <ul className="navigation-list flex m-column g2">
        <li key='1'><NavLink to='/polityka-prywatnosci'>Polityka Prywatności</NavLink></li>
        {/* <li key='2'><NavLink to='/'>Facebook</NavLink></li>
        <li key='3'><NavLink to='/'>YouTube</NavLink></li>
        <li key='4'><NavLink to='/'>Instagram</NavLink></li>
        <li key='5'><NavLink to='/design-system'>Design System</NavLink></li> */}
		  </ul>
    </div>
	)
}