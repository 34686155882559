export default function ParagraphSection({ content }) {

  const post = content.content

  return (
    <div className='paragraph-section box m-auto'>
      <div className='basic-par mb1' dangerouslySetInnerHTML={{__html: post}}></div>
    </div>
  )
}
